import {remoteForm} from '@github/remote-form'
import {showGlobalError} from '../behaviors/ajax-error'

type Kicker = import('@github/remote-form').Kicker

/**
 * Poll regularly for the status of the export job.
 */
function fetchPoll(url: string, pollIntervalMs: number) {
  return new Promise((resolve, reject) => {
    setTimeout(async () => {
      const request = new Request(url)
      request.headers.append('X-Requested-With', 'XMLHttpRequest')
      const response = await fetch(request)
      if (response.status === 200) {
        // Resource is ready.
        resolve(response)
      } else if (response.status === 202) {
        // Resource isn't ready so we're gonna retry.
        resolve(fetchPoll(url, pollIntervalMs))
      } else {
        // Something unexpected happened.
        reject(response)
      }
    }, pollIntervalMs) // Poll the endpoint every intervalMs
  })
}

/**
 * When the form submits, sets a loading state and then POSTs to create a new export job. Polls the url from the new job
 * until it's ready, finally redirecting the browser to the finished export results.
 */
async function exportEvents(loadingClass: string, menuClass: string, wants: Kicker, pollIntervalMs: number) {
  const loadingState = document.querySelector<HTMLElement>(loadingClass)!
  const menu = document.querySelector<HTMLDetailsElement>(menuClass)

  // Show/hide loading state. Set to `true` to hide menu and show loading state.
  function loading(toggle: boolean) {
    if (menu) {
      menu.hidden = toggle
      menu.open = false
    }
    loadingState.hidden = !toggle
  }

  loading(true)

  let response
  try {
    response = await wants.json()
  } catch (error) {
    loading(false)
    showGlobalError()
    return
  }

  const data = response.json

  try {
    await fetchPoll(data.job_url, pollIntervalMs)
  } catch (error) {
    loading(false)
    showGlobalError()
    return
  }

  loading(false)
  window.location = data.export_url
}

remoteForm('.js-organization-members-export-form', async function (form, wants) {
  await exportEvents('.js-organization-members-exporting', '.js-organization-members-export-menu', wants, 2000)
})

remoteForm('.js-dormant-users-export-form', async function (form, wants) {
  await exportEvents('.js-dormant-users-exporting', '.js-dormant-users-export-button', wants, 2000)
})
