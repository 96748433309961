import {onFocus, onInput} from '../onfocus'
import {fire} from 'delegated-events'

function parameterizeName(name: string): string {
  return name
    .trim()
    .toLowerCase()
    .replace(/[^a-z0-9 -]/g, '') // trim invalid characters
    .replace(/\s/g, '-') // convert spaces to hyphens
}

// auto update the slug input if needed
onInput('.js-business-name', function (event) {
  const nameInput = event.target as HTMLInputElement

  const slugInput = document.querySelector('.js-business-slug[data-auto-update]')
  if (!(slugInput instanceof HTMLInputElement)) return

  slugInput.value = parameterizeName(nameInput.value)
  fire(slugInput, 'input')
})

// turn off auto update and remove sync icon on user input
onFocus('.js-business-slug[data-auto-update]', function (slugInput) {
  slugInput.removeAttribute('data-auto-update')
  const icon = document.querySelector<SVGSVGElement>('.js-name-slug-sync-icon')
  if (icon) icon.remove()
})
